import React, { useState } from "react";
import contact_img from "../img/contact.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
    console.log(formData);
  };

  return (
    <div className="container mx-auto px-4 py-8 ">
      <h1 className="text-5xl font-extrabold text-cyan-800 text-center mb-6">
        Contact Us
      </h1>

      {/* Contact Form */}
      <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        {/* <img src={contact_img} alt="contact us" className="w-full h-auto rounded-lg" />    image contact  */}
        {/* address */}
        <div className="border rounded-lg bg-cyan-950 text-white shadow-md h-full p-6 md:p-12">
          <h2 className="text-xl font-semibold mb-4">Mumbai</h2>
          <p className="mb-2">
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              className="mr-2 text-green-500"
            />{" "}
            Aps Social Welfare and Youth Empowerment Foundation, Sandip Ganpat
            Hadbal House No.49, At- Vasuri, Post-Male, Taluka-Vikramgad,
            Dist-Palghar 421303
          </p>
          <p className="mb-2">
            <FontAwesomeIcon icon={faPhone} className="mr-2 text-green-500" />{" "}
            +91-8850380959
          </p>
          <p className="mb-4">
            <FontAwesomeIcon
              icon={faEnvelope}
              className="mr-2 text-green-500"
            />{" "}
            info@apsswyefoundation.in
          </p>
          <div className="aspect-w-16 aspect-h-9">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.209971168933!2d73.07373857499415!3d19.7036827816344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be712a80eaaaaab%3A0x1f3ec0831fe0741e!2sAPS%20SOCIAL%20WELFARE%20AND%20YOUTH%20EMPOWERMENT%20FOUNDATION!5e0!3m2!1sen!2sin!4v1719113473909!5m2!1sen!2sin"
              width="100%"
              height="256"
              style={{ border: 0 }}
              title="Mumbai"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        {/* form */}
        <form
          onSubmit={handleSubmit}
          className="bg-violet-100 shadow-lg rounded-lg p-6 md:p-12"
        >
          <h2 className="text-2xl font-semibold mb-4">Get in Touch</h2>
          <div className="mb-4">
            <label htmlFor="name" className="block text-gray-700">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="phone" className="block text-gray-700">
              Phone
            </label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block text-gray-700">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full px-3 py-10 border rounded-lg"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-cyan-950 text-white py-2 rounded-lg"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
