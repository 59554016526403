import React from 'react';
import arunImg from '../img/leadership/arun.png'; 
import sandipImg from '../img/leadership/sandip.png'; 
import kaminiImg from '../img/leadership/default.png'; 

const Leadership = () => {
  const leaders = [
    {
      name: 'Arun Kumar',
      role: 'Chairman',
      description:
        '20 Years of experience in diversified sectors- 12 years in education and 8 years as Assistant director role handling SChool Franchise, School Training schools on R & D methodologies. Worked with a number of schools and NGOs.',
      image: arunImg,
    },
    {
      name: 'Sandip Hadbal',
      role: 'Vice Chairman',
      description:
        '12 years of experience working in education and Social work sector. Working closely with rural tribal communities and implementing various projects at ground level.',
      image: sandipImg,
    },
    {
      name: 'Kamini Hire',
      role: 'Director',
      description:
        '15 years of experience working in corporate in Agriculture, Education and Social Work sectors. Passionate about betterment and empowerment of women and youth from rural communities.',
      image: kaminiImg,
    },
  ];

  return (
    <section className="bg-gray-50 py-16 px-4">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-5xl font-extrabold mb-6 text-green-800 text-center">Our Leadership</h2>
        <p className="text-center text-lg max-w-3xl mx-auto mb-12 text-gray-700">
          Our Leadership is a team of 3 people united by the same passion and goal: to heal the world and make it a better place for future generations through education, healthcare, empowerment, sustainability, and agriculture.
        </p>
        
        {/* Cards Layout */}
        <div className="grid gap-12 md:grid-cols-3">
          {leaders.map((leader, index) => (
            <div
              key={index}
              className="relative bg-white shadow-lg rounded-lg overflow-hidden transform transition-all duration-300 hover:scale-105 hover:shadow-2xl"
            >
              <div className="p-8 space-y-4">
                {/* Profile Picture and Name */}
                <div className="flex items-center space-x-4">
                  <img
                    src={leader.image}
                    alt={leader.name}
                    className="w-12 h-12 rounded-full object-cover shadow-md"
                  />
                  <div>
                    <h3 className="text-2xl font-bold text-green-800">{leader.name}</h3>
                    <h4 className="text-green-600 font-semibold">{leader.role}</h4>
                  </div>
                </div>
                {/* Description */}
                <p className="text-gray-600">{leader.description}</p>
              </div>
              <div className="absolute top-0 left-0 h-2 w-full bg-gradient-to-r from-green-600 to-green-400"></div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Leadership;
