import React from 'react'

import DonateHelper from './DonateHelper'

const Donate = () => {

    
    return (
        <>

            <div className="w-full flex justify-center items-center bg-gray-100">
                <DonateHelper/>
            </div>

        </>
    )
}

export default Donate