import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css'; // Import the styles for yet-another-react-lightbox

import p1 from '../img/p1.jpg';
import p2 from '../img/p2.jpg';
import p3 from '../img/p3.jpg';

const Projects = () => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const images = [
    { src: p1, caption: 'Project 1' },
    { src: p2, caption: 'Project 2' },
    { src: p3, caption: 'Project 3' },
  ];

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  return (
    <div className="py-12 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-5xl font-extrabold mb-6 text-cyan-800 text-center">
          Our Projects
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {images.map((image, index) => (
            <div key={index} className="overflow-hidden rounded-lg shadow-lg">
              <img
                src={image.src}
                alt={`Project ${index + 1}`}
                className="w-full h-auto cursor-pointer"
                onClick={() => openLightbox(index)}
              />
            </div>
          ))}
        </div>
        {lightboxOpen && (
          <Lightbox
            open={lightboxOpen}
            close={closeLightbox}
            slides={images}
            currentIndex={photoIndex}
            slideCaption={(slide, idx) => (
              <div className="text-center">
                <p className="text-lg text-gray-800">{slide.caption}</p>
              </div>
            )}
          />
        )}
      </div>
    </div>
  );
};

export default Projects;
