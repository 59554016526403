import { Outlet } from "react-router-dom";
import Header from "./Header.js";
import Footer from "./Footer.js";
// import DonateButton from './DonateButton.js'

const Layout = () => {
  return (
    <>
      <Header />
      <main className="mt-32">
        <Outlet />
      </main>
      <Footer />
    </>
  );
};
export default Layout;
