import React from 'react';

const OurApproach = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            {/* Heading */}
            <div className="text-center mb-12">
                <h1 className="text-5xl font-extrabold mb-6 text-cyan-800">Our Approach</h1>
                <p className="text-gray-700 text-lg mx-auto">
                    At APS Foundation, we believe in a holistic approach to community development that addresses the interconnected challenges of education, healthcare, youth empowerment, women’s empowerment, and sustainable agriculture.
                </p>
            </div>

            {/* Threaded Section */}
            <div className="relative max-w-full mx-auto">
                <div className="absolute w-1 left-1/2  bg-gray-300 h-full"></div> {/* Central line */}

                {/* Thread Cards */}
                <div className="flex flex-col space-y-12">
                    {/* Card 1 */}
                    <div className="relative flex items-center">
                        <div className="bg-white p-6 shadow-lg rounded-lg w-full md:w-8/12 ml-auto border-l-4 border-cyan-800">
                            <h3 className="text-2xl font-semibold text-cyan-900">Community-Centered Initiatives</h3>
                            <p className="text-gray-600 mt-2">
                                We engage directly with local tribal communities to understand their unique needs and aspirations. Our programs involve community members to ensure relevance, effectiveness, and cultural sensitivity.
                            </p>
                        </div>
                    </div>

                    {/* Card 2 */}
                    <div className="relative flex items-center">
                        <div className="bg-white p-6 shadow-lg rounded-lg w-full lg:w-3/4 mr-auto border-l-4 border-cyan-800">
                            <h3 className="text-2xl font-semibold text-cyan-900">Integrated Programs</h3>
                            <p className="text-gray-600 mt-2">
                                Our programs recognize that education, health, and economic empowerment are interconnected. We provide educational resources that also promote health and nutrition for holistic development.
                            </p>
                        </div>
                        
                    </div>

                    {/* Card 3 */}
                    <div className="relative flex items-center">
                        <div className="bg-white p-6 shadow-lg rounded-lg w-full lg:w-3/4 ml-auto border-l-4 border-cyan-800">
                            <h3 className="text-2xl font-semibold text-cyan-900">Capacity Building</h3>
                            <p className="text-gray-600 mt-2">
                                We focus on capacity building through training, mentorship, and skill development, empowering individuals and organizations to lead change within their communities.
                            </p>
                        </div>
                        
                    </div>

                    {/* Card 4 */}
                    <div className="relative flex items-center">
                        <div className="bg-white p-6 shadow-lg rounded-lg w-full lg:w-3/4 mr-auto border-l-4 border-cyan-800">
                            <h3 className="text-2xl font-semibold text-cyan-900">Partnerships and Collaborations</h3>
                            <p className="text-gray-600 mt-2">
                                We believe in collaboration. Partnering with local organizations, governments, and stakeholders allows us to leverage resources and expertise for maximum impact.
                            </p>
                        </div>
                        
                    </div>

                    {/* Card 5 */}
                    <div className="relative flex items-center">
                        <div className="bg-white p-6 shadow-lg rounded-lg w-full lg:w-3/4 ml-auto border-l-4 border-cyan-800">
                            <h3 className="text-2xl font-semibold text-cyan-900">Sustainable Practices</h3>
                            <p className="text-gray-600 mt-2">
                                In agriculture, we advocate sustainable practices that enhance food security and protect the environment. We educate farmers on eco-friendly techniques to promote biodiversity and resilience.
                            </p>
                        </div>
                        
                    </div>

                    {/* Card 6 */}
                    <div className="relative flex items-center">
                        <div className="bg-white p-6 shadow-lg rounded-lg w-full lg:w-3/4 mr-auto border-l-4 border-cyan-800">
                            <h3 className="text-2xl font-semibold text-cyan-900">Monitoring and Evaluation</h3>
                            <p className="text-gray-600 mt-2">
                                We implement monitoring and evaluation systems to ensure program effectiveness. We gather feedback and make adjustments to continuously improve our impact.
                            </p>
                        </div>
                        
                    </div>

                    {/* Card 7 */}
                    <div className="relative flex items-center">
                        <div className="bg-white p-6 shadow-lg rounded-lg w-full lg:w-3/4 ml-auto border-l-4 border-cyan-800">
                            <h3 className="text-2xl font-semibold text-cyan-900">Advocacy and Awareness</h3>
                            <p className="text-gray-600 mt-2">
                                We raise awareness about critical issues such as education, healthcare, gender equality, and sustainable agriculture, influencing policy and inspiring action.
                            </p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurApproach;
