import { faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'



const DonateButton = () => {
    return (
        <>
            {/* Floating Donation Button */}
            <Link to="/donate" >
                <button
                    className="fixed bottom-4 right-4 bg-green-700 text-white px-4 py-2 rounded-full shadow-lg hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 flex items-center space-x-2"
                >
                    <FontAwesomeIcon icon={faHandHoldingHeart} />
                    <span>Donate</span>
                </button>
            </Link>
        </>
    )
}

export default DonateButton