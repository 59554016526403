// src/components/OurValues.js

import React from 'react';

const valuesData = [
  {
    icon: '🔗',
    title: 'Equity and Inclusion',
    description: 'Ensuring that all individuals, regardless of their background or circumstances, have equal access to education and healthcare services.',
  },
  {
    icon: '❤️',
    title: 'Compassion and Empathy',
    description: 'Demonstrating care, understanding, and empathy towards the needs and challenges faced by the communities served.',
  },
  {
    icon: '🔍',
    title: 'Integrity and Transparency',
    description: 'Upholding high ethical standards, transparency in operations, and accountability to stakeholders.',
  },
  {
    icon: '🤝',
    title: 'Collaboration and Partnership',
    description: 'Engaging with local communities, governments, other NGOs, and stakeholders to maximize impact and effectiveness.',
  },
  {
    icon: '💡',
    title: 'Innovation and Adaptability',
    description: 'Embracing innovative approaches and adapting to changing needs and circumstances to continuously improve programs and services.',
  },
  {
    icon: '🌱',
    title: 'Sustainability and Responsibility',
    description: 'Promoting sustainable practices that ensure long-term benefits for both the communities and the environment.',
  },
  {
    icon: '🙌',
    title: 'Respect and Dignity',
    description: 'Treating all individuals with respect, dignity, and recognizing their inherent worth and rights.',
  },
  {
    icon: '🔧',
    title: 'Empowerment and Participation',
    description: 'Empowering communities to actively participate in decision-making processes and take ownership of their own development.',
  },
  {
    icon: '🏆',
    title: 'Quality and Excellence',
    description: 'Committing to delivering high-quality education and healthcare services/resources that meet or exceed recognized standards.',
  },
  {
    icon: '📢',
    title: 'Advocacy and Social Justice',
    description: 'Advocating for policies and practices that promote social justice, equality, and human rights in education and healthcare.',
  },
];

const OurValues = () => {
  return (
    <section className="pt-2 md:pt-6 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-8">Our Values</h2>
        <div className="grid grid-cols-1 ">
          {valuesData.map((value, index) => (
            <React.Fragment key={index}>
              <div className={`flex items-start p-6 bg-white border-2 border-dashed border-green-800 rounded-lg shadow-md ${index % 2 === 0 ? 'mr-auto' : 'ml-auto'}`}>
                <span className="text-4xl">{value.icon}</span>
                <div className="ml-4">
                  <h3 className="text-xl font-semibold">{value.title}</h3>
                  <p className="text-gray-700">{value.description}</p>
                </div>
              </div>
              {index < valuesData.length - 1 && ( 
                <div className='w-1 h-8 bg-green-800 block mx-auto' /> // Vertical Line
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurValues;
