import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faChevronDown, faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faLinkedinIn, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons'; 
import logo from '../img/logo.png';
import DonateButton from './DonateButton';

const Header = () => {
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false); 
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  const socialLinks = [
    { href: "https://www.facebook.com", icon: faFacebookF, label: "Facebook" },
    { href: "https://www.instagram.com", icon: faInstagram, label: "Instagram" },
    { href: "https://www.linkedin.com", icon: faLinkedinIn, label: "LinkedIn" },
    { href: "https://www.youtube.com", icon: faYoutube, label: "YouTube" },
    { href: "https://www.whatsapp.com", icon: faWhatsapp, label: "WhatsApp" },
  ];

  return (
    <>
      {/* Top Bar */}
      <div className="fixed bg-cyan-950 py-2 w-full z-40 top-0">
        <div className="container mx-auto px-4 flex flex-wrap justify-between items-center text-white">
          {/* Phone and Email Section */}
          <div className="flex space-x-4 font-semibold mb-2 md:mb-0">
            <a href="tel:+917065960001" className="text-white flex items-center">
              <FontAwesomeIcon icon={faPhone} className="text-green-600" />
              <p className='hidden md:inline ml-1'>91-7065960001</p>
            </a>
            <a href="mailto:info@apsswyefoundation.in" className="text-white flex items-center">
              <FontAwesomeIcon icon={faEnvelope} className="text-green-600" />
              <p className='hidden md:inline ml-1'>apsfoundation2021@gmail.com</p>
            </a>
          </div>

          {/* Social Media Icons and Login Button */}
          <div className="flex items-center space-x-4">
            {socialLinks.map(({ href, icon, label }) => (
              <a key={label} href={href} target="_blank" rel="noopener noreferrer" className="text-white hover:text-green-700" aria-label={label}>
                <FontAwesomeIcon icon={icon} size="lg" />
              </a>
            ))}
            <button className="bg-green-700 text-white font-semibold px-4 py-1 rounded hover:bg-green-800">
              Login
            </button>
          </div>
        </div>
      </div>

      {/* Logo and Navigation */}
      <div className="border-b py-1 shadow-md fixed w-full z-30 top-12 bg-white">
        <div className="container mx-auto px-4 flex items-center justify-between">
          <a href="/" className='flex items-center'>
            <img src={logo} alt="Logo" className="h-24 mr-4" />
            <h1 className="text-2xl font-bold hidden md:block">APS SOCIAL WELFARE AND YOUTH EMPOWERMENT FOUNDATION</h1>
          </a>

          {/* Hamburger Icon for Mobile */}
          <button onClick={toggleMobileMenu} className="p-2 text-green-700 md:hidden" aria-label="Toggle navigation">
            <FontAwesomeIcon icon={faBars} size="2xl" />
          </button>

          {/* Desktop Navigation */}
          <div className="hidden md:flex md:w-3/4">
            <nav className="flex justify-end space-x-6 font-semibold font-sans">
              <Link to="/" className="px-4 py-2 hover:text-green-700">Home</Link>
              <Link to="/about" className="px-4 py-2 hover:text-green-700">About Us</Link>
              <Link to="/projects" className="px-4 py-2 hover:text-green-700">Projects</Link>
              <Link to="/gallery" className="px-4 py-2 hover:text-green-700">Gallery</Link>
              <Link to="/contact" className="px-4 py-2 hover:text-green-700">Contact Us</Link>
              <div className="relative">
                <button onClick={toggleDropdown} className="px-4 py-2 hover:text-green-700 flex items-center">
                  More about us <FontAwesomeIcon icon={faChevronDown} className="ml-2 text-sm" />
                </button>
                {dropdownOpen && (
                  <div className="absolute right-0 mt-2 w-56 bg-white shadow-lg rounded-md z-50">
                    <Link to="/awards" className="block px-4 py-2 text-gray-700 hover:bg-green-100" onClick={() => setDropdownOpen(false)}>Awards</Link>
                    <Link to="/team" className="block px-4 py-2 text-gray-700 hover:bg-green-100" onClick={() => setDropdownOpen(false)}>Our Team</Link>
                    <Link to="/approach" className="block px-4 py-2 text-gray-700 hover:bg-green-100" onClick={() => setDropdownOpen(false)}>Our Approach</Link>
                    <Link to="/leadership" className="block px-4 py-2 text-gray-700 hover:bg-green-100" onClick={() => setDropdownOpen(false)}>Our Leadership</Link>
                    <Link to="/media" className="block px-4 py-2 text-gray-700 hover:bg-green-100" onClick={() => setDropdownOpen(false)}>Media Coverage</Link>
                    <Link to="/presence" className="block px-4 py-2 text-gray-700 hover:bg-green-100" onClick={() => setDropdownOpen(false)}>Geographical Presence</Link>
                  </div>
                )}
              </div>
            </nav>
          </div>
        </div>
      </div>

      {/* Mobile Menu - Slide-in Sidebar */}
      <div
        className={`fixed top-0 right-0 h-full w-64 bg-white shadow-lg z-50 transform ${
          isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'
        } transition-transform duration-300 ease-in-out`}
      >
        <button onClick={toggleMobileMenu} className="absolute top-4 right-4 text-green-700" aria-label="Close menu">
          <FontAwesomeIcon icon={faXmark} size="2xl" className='text-cyan-900' />
        </button>
        <nav className="flex flex-col space-y-4 mt-16 text-lg font-semibold px-4">
          <Link to="/" className="hover:text-green-700" onClick={toggleMobileMenu}>Home</Link>
          <Link to="/about" className="hover:text-green-700" onClick={toggleMobileMenu}>About Us</Link>
          <Link to="/projects" className="hover:text-green-700" onClick={toggleMobileMenu}>Projects</Link>
          <Link to="/gallery" className="hover:text-green-700" onClick={toggleMobileMenu}>Gallery</Link>
          <Link to="/contact" className="hover:text-green-700" onClick={toggleMobileMenu}>Contact Us</Link>
          <div>
            <button onClick={toggleDropdown} className="hover:text-green-700 flex items-center">
              More about us <FontAwesomeIcon icon={faChevronDown} className="ml-2 text-sm" />
            </button>
            {dropdownOpen && (
              <div className="ml-4 space-y-2 mt-2 text-lg">
                <Link to="/awards" className="block hover:text-green-700" onClick={() => { setDropdownOpen(false); toggleMobileMenu(); }}>Awards</Link>
                <Link to="/team" className="block hover:text-green-700" onClick={() => { setDropdownOpen(false); toggleMobileMenu(); }}>Our Team</Link>
                <Link to="/approach" className="block hover:text-green-700" onClick={() => { setDropdownOpen(false); toggleMobileMenu(); }}>Our Approach</Link>
                <Link to="/leadership" className="block hover:text-green-700" onClick={() => { setDropdownOpen(false); toggleMobileMenu(); }}>Our Leadership</Link>
                <Link to="/media" className="block hover:text-green-700" onClick={() => { setDropdownOpen(false); toggleMobileMenu(); }}>Media Coverage</Link>
                <Link to="/presence" className="block hover:text-green-700" onClick={() => { setDropdownOpen(false); toggleMobileMenu(); }}>Geographical Presence</Link>
              </div>
            )}
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
