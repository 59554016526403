import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

const DonateHelper = () => {
    const [donationAmount, setDonationAmount] = useState('');

    const handleAmountSelection = (e) => {
        const value = e.target.value;
        setDonationAmount(value);
    };

    const handleDonationAmountChange = (e) => {
        setDonationAmount(e.target.value);
    };

    const handleSubmit = () => {
        if (donationAmount) {
            alert(`You have chosen to donate ₹ ${donationAmount}`);
        }
    };

    const amounts = ['1cr', '50L', '25L', '10L', '5L', '2.5L', '1L', '50K', '25K', '20K', '10K', '5K'];

    return (
        <div className="bg-gray-200 w-full md:w-1/2 rounded-lg p-4 md:p-6">
            <h2 className="text-xl font-bold mb-4 flex items-center">
                <FontAwesomeIcon icon={faHeart} className="text-red-600 mr-2" />
                Choose Your Donation Amount
            </h2>
            <div className="grid grid-cols-3 gap-4">
                {amounts.map((amount, i) => (
                    <button
                        key={i}
                        value={amount}
                        onClick={handleAmountSelection}
                        className="bg-green-700 text-white p-4 rounded hover:bg-green-900"
                    >
                        ₹ {amount}
                    </button>
                ))}
            </div>

            <div className="mt-4 flex items-center space-x-4 ">
                <label
                    htmlFor="custom-amount"
                    className="text-lg font-medium text-gray-700 whitespace-nowrap"
                >
                    Monthly:
                </label>
                <input
                    type="number"
                    name="custom-amount"
                    placeholder="Enter Custom Amount"
                    value={donationAmount}
                    onChange={handleDonationAmountChange}
                    className="p-2 border border-gray-300 rounded w-full"
                />
                <button
                    onClick={handleSubmit}
                    className="bg-green-700 text-white p-2 rounded hover:bg-green-900 flex items-center space-x-2"
                >
                    <span>Donate</span>
                    <FontAwesomeIcon icon={faHeart} />
                </button>
            </div>
        </div>
    );
};

export default DonateHelper;
