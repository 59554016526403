import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faChevronRight, faHeartbeat, faUsers, faFistRaised, faSeedling, faHandsHelping, faGraduationCap } from '@fortawesome/free-solid-svg-icons';


import team_member1 from '../img/team_member1.png';
import team_member2 from '../img/team_member2.png';
import team_member3 from '../img/team_member3.png';
import team_member4 from '../img/team_member4.png';

const About = () => {
    return (
        <div className="container mx-auto px-4 py-8">

            {/* About Us Section */}
            <div className="text-center mb-16">
                <h1 className="text-5xl font-extrabold mb-6 text-cyan-800">About Us</h1>
                <h2 className="text-3xl font-bold text-cyan-700 mb-4">APS Social Welfare and Youth Empowerment Foundation</h2>
                <p className="text-gray-600">“Heal the world & make it a better place to live”</p>
            </div>

            {/* Focus Areas Section */}
            <div className="max-w-6xl mx-auto">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 mb-16">
                    {/* Education */}
                    <div className="bg-white rounded-lg shadow-lg p-8">
                        <h3 className="text-xl font-semibold text-cyan-800 mb-3 flex items-center">
                            <FontAwesomeIcon icon={faGraduationCap} className="mr-3 text-cyan-600" />
                            Education
                        </h3>
                        <p className="text-gray-600">
                            Providing access to quality education, materials, and stationery to foster a learning environment that promotes creativity and personal growth.
                        </p>
                    </div>

                    {/* Healthcare */}
                    <div className="bg-white rounded-lg shadow-lg p-8">
                        <h3 className="text-xl font-semibold text-cyan-800 mb-3 flex items-center">
                            <FontAwesomeIcon icon={faHeartbeat} className="mr-3 text-cyan-600" />
                            Healthcare
                        </h3>
                        <p className="text-gray-600">
                            Advocating for improved healthcare services, preventive care, and training ASHA workers for better health awareness and resources.
                        </p>
                    </div>

                    {/* Youth Empowerment */}
                    <div className="bg-white rounded-lg shadow-lg p-8">
                        <h3 className="text-xl font-semibold text-cyan-800 mb-3 flex items-center">
                            <FontAwesomeIcon icon={faUsers} className="mr-3 text-cyan-600" />
                            Youth Empowerment
                        </h3>
                        <p className="text-gray-600">
                            Providing leadership training and mentorship to help young people realize their potential and become change-makers.
                        </p>
                    </div>

                    {/* Women's Empowerment */}
                    <div className="bg-white rounded-lg shadow-lg p-8">
                        <h3 className="text-xl font-semibold text-cyan-800 mb-3 flex items-center">
                            <FontAwesomeIcon icon={faFistRaised} className="mr-3 text-cyan-600" />
                            Women's Empowerment
                        </h3>
                        <p className="text-gray-600">
                            Championing gender equality by supporting women's economic independence, health, and leadership roles.
                        </p>
                    </div>

                    {/* Sustainable Agriculture */}
                    <div className="bg-white rounded-lg shadow-lg p-8">
                        <h3 className="text-xl font-semibold text-cyan-800 mb-3 flex items-center">
                            <FontAwesomeIcon icon={faSeedling} className="mr-3 text-cyan-600" />
                            Sustainable Agriculture
                        </h3>
                        <p className="text-gray-600">
                            Promoting sustainable farming practices that enhance food security and empower farmers with education and resources.
                        </p>
                    </div>



                </div>
                {/* Final Quote */}
                <div className="text-center mt-12">
                    <p className="text-lg text-gray-600 max-w-3xl mx-auto italic font-semibold">
                        At APS, we believe that by investing in education and empowerment, we can break the cycle of poverty and create a brighter future for all. Join us in our mission to uplift communities and build a sustainable, equitable world. Together, we can make a difference!
                    </p>
                </div>
            </div>

            {/* About Us Section end */}


            <div className="text-center mb-12">
                <h2 className="text-2xl font-semibold mb-4">Our Team</h2>
                <p className="text-gray-600 mb-8">Meet the dedicated individuals working tirelessly to achieve our mission and vision.</p>

                <div className="flex flex-wrap justify-center mx-4">
                    <div className="w-full sm:w-1/2 lg:w-1/4 px-4 mb-8">
                        <img src={team_member1} alt="Team Member 1" className="w-full h-64 object-cover rounded-lg shadow-lg mb-4" />
                        <h3 className="text-xl font-semibold">Mr. Arun Kumar Nayak</h3>
                        <p className="text-gray-600">Founder & Chairman</p>
                    </div>
                    <div className="w-full sm:w-1/2 lg:w-1/4 px-4 mb-8">
                        <img src={team_member2} alt="Team Member 2" className="w-full h-64 object-cover rounded-lg shadow-lg mb-4" />
                        <h3 className="text-xl font-semibold">Mr. Sandip Ganpa Hadbal</h3>
                        <p className="text-gray-600">Director</p>
                    </div>
                    <div className="w-full sm:w-1/2 lg:w-1/4 px-4 mb-8">
                        <img src={team_member3} alt="Team Member 3" className="w-full h-64 object-cover rounded-lg shadow-lg mb-4" />
                        <h3 className="text-xl font-semibold">Shri Somveer</h3>
                        <p className="text-gray-600">Independent Director</p>
                    </div>
                    <div className="w-full sm:w-1/2 lg:w-1/4 px-4 mb-8">
                        <img src={team_member4} alt="Team Member 4" className="w-full h-64 object-cover rounded-lg shadow-lg mb-4" />
                        <h3 className="text-xl font-semibold">Mr. Sanjay Kumar</h3>
                        <p className="text-gray-600">Manager Odisha</p>
                    </div>
                </div>

                <div className="text-center">
                    <Link to="/team" className="inline-block px-6 py-2 bg-cyan-800 text-white font-semibold rounded-lg shadow-md hover:bg-cyan-700 transition duration-300">
                        View All Team Members
                    </Link>
                </div>
            </div>

            {/* Final section */}
            <div className="mb-12">
                <h2 className="text-3xl font-bold text-center text-cyan-950  0 mb-6">We are made of three attributes</h2>
                <div className="overflow-x-auto mx-4">
                    <table className="min-w-full bg-white rounded-lg shadow-lg">
                        <thead>
                            <tr className="bg-cyan-800 text-white">
                                <th className="w-1/3 px-4 py-2 text-lg">Attribute</th>
                                <th className="w-1/3 px-4 py-2 text-lg">Description</th>
                                <th className="w-1/3 px-4 py-2 text-lg">Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bg-gray-100 border-b border-gray-200">
                                <td className="px-4 py-4 font-semibold text-cyan-800">INDISPENSABLE - अपरिहार्य</td>
                                <td className="px-4 py-4">
                                    In environments like this, APS is the modest, prudent, long-term strategy for youth empowerment
                                    <br /><br />
                                    <i>Marathi- या प्रकारच्या परिसरांमध्ये, APS म्हणजे युवा सशक्तीकरणासाठी संवेदनशील, विवेकपूर्ण, दीर्घकालिक युक्ती आहे.</i>
                                    <br /><br />
                                    <i>Hindi- इस तरह के वातावरण में, APS युवा सशक्तिकरण के लिए संकेत, सतर्क और दीर्घकालिक रणनीति है।</i>
                                </td>
                                <td className="px-4 py-4">1) MR.ARUN KUMAR NAYAK- अपरिहार्य</td>
                            </tr>
                            <tr className="bg-white border-b border-gray-200">
                                <td className="px-4 py-4 font-semibold text-cyan-800">PRAYAG - प्रयाग</td>
                                <td className="px-4 py-4">
                                    We are the amalgamation of people’s strength, belief and expectations. To do something in terms of higher order. Hardcore activities to change their present unbearable condition.
                                    <br /><br />
                                    <i>Marathi- आम्ही, लोकांची शक्ती, विश्वास आणि अपेक्षा यांचे एकत्रिकरण. उच्च क्रमाने काहीतरी करण्यासाठी. त्यांची सध्याची असह्य स्थिती बदलण्यासाठी कडक उपक्रम</i>
                                    <br /><br />
                                    <i>Hindi- हम, लोगों की ताकत, विश्वास और अपेक्षाओं का समामेलन। उच्च क्रम के संदर्भ में कुछ करने के लिए। अपनी वर्तमान असहनीय स्थिति को बदलने के लिए कट्टर गतिविधियाँ।</i>
                                </td>
                                <td className="px-4 py-4">1) MR.ARUN KUMAR NAYAK- प्रयाग</td>
                            </tr>
                            <tr className="bg-gray-100">
                                <td className="px-4 py-4 font-semibold text-cyan-800">STRUGGLE - संग्राम</td>
                                <td className="px-4 py-4">
                                    We are fighting to heal the world and to create a better world for all of us to live in the universe.
                                    <br /><br />
                                    <i>Marathi- आपण जग बरे करण्यासाठी आणि आपल्या सर्वांसाठी एक चांगले जग निर्माण करण्यासाठी, विश्वात राहण्यासाठी लढत आहोत.</i>
                                    <br /><br />
                                    <i>Hindi- हम मानव गतिविधि, पृथ्वी, पशु जगत में कृत्यों के खिलाफ खड़े हैं। हम दुनिया को ठीक करने और हम सभी के लिए एक बेहतर दुनिया बनाने के लिए, ब्रह्मांड में रहने के लिए लड़ रहे हैं.</i>
                                </td>
                                <td className="px-4 py-4">2) MR.SANDIP GANPAT HADBAL- संग्राम</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="mb-12">
                <h2 className="text-3xl font-bold text-center text-cyan-900 mb-6">The objects for which the organization is established are:</h2>
                <ul className="list-inside text-gray-700 leading-relaxed space-y-4 ml-8 mx-4">
                    <li className="mb-4">
                        <span className="block mb-2"><FontAwesomeIcon icon={faStar} className="mr-3 text-yellow-500 text-lg" /> To create awareness and launch rural youth entrepreneurship projects to enable and promote rural youth to create village sustainability.</span>
                    </li>
                    <li className="mb-4">
                        <span className="block mb-2"><FontAwesomeIcon icon={faStar} className="mr-3 text-yellow-500 text-lg" /> To create awareness regarding agricultural practices and its scope in the development of the economy.</span>
                    </li>
                    <li className="mb-4">
                        <span className="block mb-2"><FontAwesomeIcon icon={faStar} className="mr-3 text-yellow-500 text-lg" /> To initiate vocational schools or training centers to enable rural youth to create village entrepreneurship ecosystems to encourage youth to work in villages.</span>
                    </li>
                    <li className="mb-4">
                        <span className="block mb-2"><FontAwesomeIcon icon={faStar} className="mr-3 text-yellow-500 text-lg" /> To provide social, educational, and economic empowerment to women and children. Our aim is to safeguard the rights of the child and provide for the wellbeing of children in need, more particularly:</span>
                        <ul className="list-inside ml-4">
                            <li className="mb-2"><FontAwesomeIcon icon={faChevronRight} className="mr-2 text-cyan-800" /> Children exposed to any form of abus.</li>
                            <li className="mb-2"><FontAwesomeIcon icon={faChevronRight} className="mr-2 text-cyan-800" /> Children deprived of education.</li>
                            <li className="mb-2"><FontAwesomeIcon icon={faChevronRight} className="mr-2 text-cyan-800" /> Children suffering from ill health, physical and mental.</li>
                        </ul>
                    </li>
                    <li className="mb-4">
                        <span className="block mb-2"><FontAwesomeIcon icon={faStar} className="mr-3 text-yellow-500 text-lg" /> To identify the homeless and the destitute and facilitate their relocation in appropriate institutions.</span>
                    </li>
                    <li className="mb-4">
                        <span className="block mb-2"><FontAwesomeIcon icon={faStar} className="mr-3 text-yellow-500 text-lg" /> To empower tribal youths by providing medical and legal aid and assistance to the tribal and to take such other measures to ensure their social wellbeing, including:</span>
                        <ul className=" list-inside ml-4">
                            <li className="mb-2"><FontAwesomeIcon icon={faChevronRight} className="mr-2 text-cyan-800" /> Medical aid and appliances.</li>
                            <li className="mb-2"><FontAwesomeIcon icon={faChevronRight} className="mr-2 text-cyan-800" /> Vocational training and employment opportunities.</li>
                            <li className="mb-2"><FontAwesomeIcon icon={faChevronRight} className="mr-2 text-cyan-800" /> Legal aid.</li>
                            <li className="mb-2"><FontAwesomeIcon icon={faChevronRight} className="mr-2 text-cyan-800" /> Other rehabilitation measures necessary to ensure them a self-sustained living.</li>
                        </ul>
                    </li>
                    <li className="mb-4">
                        <span className="block mb-2"><FontAwesomeIcon icon={faStar} className="mr-3 text-yellow-500 text-lg" /> To preserve and protect the environment and propagate the significance of sustainable development for maintaining ecological balance.</span>
                    </li>
                    <li className="mb-4">
                        <span className="block mb-2"><FontAwesomeIcon icon={faStar} className="mr-3 text-yellow-500 text-lg" /> To take such other measures and render services or assistance including the establishment of information centers, provision for shelter homes, setting up of educational, research and training institutions, etc., to the aforesaid class of persons and other persons, as may be necessary from time to time for the betterment of society.</span>
                    </li>
                </ul>
            </div>

        </div>
    );
}

export default About;
