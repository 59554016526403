import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Public from './components/Public'
import Login from './features/auth/Login';
import Register from './features/auth/Register'
import DashLayout from './components/DashLayout'
import NotesList from './features/notes/NotesList'
import Prefetch from './features/auth/Prefetch'
import PersistLogin from './features/auth/PersistLogin'
import useTitle from './hooks/useTitle';
import './index.css'

import NewTeamForm from './features/teams/NewTeamForm';
import TeamsList from './features/teams/TeamsList'

// 
import About from './components/About'
import Team from './components/Team'
import Projects from './components/Projects'
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import Donate from './components/Donate';
import Approach from './components/Approach';

import GeoPresence from './components/GeoPresence';
import Leadership from './components/Leadership'
import Awards from './components/Awards'
import MediaCoverage from './components/MediaCoverage';
// 

function App() {
  useTitle('APS Social Welfare and Youth Empowerment Foundation')
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route index element={<Public />} />
        <Route path='/about' element={<About />}/>
        <Route path='/team' element={<Team />}/>
        <Route path='/projects' element={<Projects/>}/>
        <Route path='/gallery' element={<Gallery/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/donate' element={<Donate/>}/>
        <Route path='/approach' element={<Approach/>}/>
        <Route path='/leadership' element={<Leadership/>}/>
        <Route path='/awards' element={<Awards/>} />
        <Route path='/media' element={<MediaCoverage/>} />


        <Route path='/presence' element={<GeoPresence/>}/>


        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />


        {/* Protected routes */}
        <Route element={<PersistLogin />}>
          <Route element={<Prefetch />}>
            <Route path="dash" element={<DashLayout />}>

              <Route index element={<TeamsList />} />
              <Route path="users">
              </Route>

              <Route path="team">
                <Route index element={<TeamsList />} />
                <Route path="new" element={<NewTeamForm />} />
              </Route>
              <Route path="notes">
                <Route index element={<NotesList />} />
              </Route>

            </Route>



          </Route>{/* End Dash */}
        </Route>
      </Route>
    </Routes>
  );
}

export default App;