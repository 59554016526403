import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import logo from '../img/logo.png';
import DonorsCarousel from './DonorsCarousel'


const Footer = () => {
    return (
        <>
            {/* Footer */}
            <footer className="bg-cyan-950 text-white py-8">
                <div className="container mx-auto px-8">
                    <div className="flex flex-wrap -mx-4">
                        <div className="w-full md:w-1/3 mb-6 px-4 md:mb-0">
                            <img src={logo} alt="Logo" className="h-36 mb-4" />
                            <p>APS Social Welfare and Youth Empowerment Foundation to create awareness and launch rural youth entrepreneurship projects to enable and promote rural youth to create village sustainability.</p>
                        </div>

                        <div className="w-full md:w-1/3 mb-6 md:mb-0">
                            <h4 className="text-lg font-semibold mb-2">Contact Us</h4>


                            <p className="mt-4"><FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" /> <span className='s font-extrabold'>MUMBAI:</span> APS SOCIAL WELFARE AND YOUTH EMPOWERMENT FOUNDATION, SANDIP GANPAT HADBAL HOUSE NO.49, AT - VASURI, POST-MALE, TALUKA-VIKRAMGAD, DIST-PALGHAR 421303</p>
                            <p className='mb-2'><FontAwesomeIcon icon={faPhone} className="mr-2" /> +91-8850380959</p>
                            <DonorsCarousel />

                        </div>
                        

                        <div className="w-full md:w-1/3 px-4">
                            <h4 className="text-lg font-semibold mb-2">Our Location</h4>
                            <div className="w-full  bg-gray-900 border-4 border-cyan-800">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.287686770415!2d76.9676766752886!3d28.62113867567091!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0fc9f4dce5fb%3A0x5f61818efa4ff3d1!2sAPS%20SOCIAL%20WELFARE%20AND%20YOUTH%20EMPOWERMENT%20FOUNDATION!5e0!3m2!1sen!2sin!4v1718589435086!5m2!1sen!2sin" width="100%" height="360" style={{ border: 0 }}
                                    allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer