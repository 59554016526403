import React, { useState, useEffect, useRef } from 'react';
import banner1 from '../img/banner1.jpg';
import banner2 from '../img/banner2.jpg';
import banner3 from '../img/banner3.jpg';
import banner4 from '../img/banner4.jpg';
import banner5 from '../img/banner5.jpg';
import introImage from '../img/programs.jpg';
import DonateHelper from './DonateHelper';
import OurValues from './OurValues';


import mission_img from '../img/mission.png';
import vision_img from '../img/vision.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faRocket } from '@fortawesome/free-solid-svg-icons';


const Public = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [progress, setProgress] = useState(0);
    const totalSlides = 5;
    const slideInterval = 6000;

    const slideIntervalRef = useRef(null);
    const progressIntervalRef = useRef(null);

    const resetIntervals = () => {
        if (slideIntervalRef.current) {
            clearInterval(slideIntervalRef.current);
        }
        if (progressIntervalRef.current) {
            clearInterval(progressIntervalRef.current);
        }
    };

    useEffect(() => {
        const startIntervals = () => {
            slideIntervalRef.current = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex < totalSlides - 1 ? prevIndex + 1 : 0));
                setProgress(0);
            }, slideInterval);

            progressIntervalRef.current = setInterval(() => {
                setProgress((prevProgress) => {
                    const increment = 100 / (slideInterval / 100);
                    return prevProgress + increment;
                });
            }, 100);
        };

        startIntervals();

        return () => resetIntervals();
    }, []);

    const updateCarousel = () => {
        return {
            transform: `translateX(-${currentIndex * 100}%)`,
        };
    };

    const buttons = [
        { text: 'Empowering Education', textM:'Education' , index: 0 },
        { text: 'Blanket Distribution', textM:'Distribution' , index: 1 },
        { text: 'Medical Camps',textM:'Camps' , index: 2 },
        { text: 'Tree Plantation',textM:'Trees' ,  index: 3 },
        { text: 'Training Programs',textM:'Training' ,  index: 4 },
    ];

    return (
        <div className='bg-gray-100'>
            {/* Carousel Banner */}
            <div className="relative">
                <div className="relative h-[580px] overflow-hidden">
                    <div className="absolute inset-0 flex transition-transform duration-500 ease-in-out" style={updateCarousel()}>
                        {[
                            { img: banner1, title: 'Empowering Education', description: 'APS Social Welfare Foundation distributes educational materials to rural students. Join us in our mission to provide resources and support for brighter futures and stronger communities.' },
                            { img: banner2, title: 'Blanket Distribution', description: 'Our volunteers distributing blankets to rural villages, ensuring no one is left in the cold. Join us in providing comfort and support to those in need.' },
                            { img: banner3, title: 'Medical Camps', description: 'APS Social Welfare Foundation organizes medical camps in rural areas, offering essential healthcare services to those in need. Together, we can ensure healthier communities.' },
                            { img: banner4, title: 'Tree Plantation', description: "APS Social Welfare Foundation's tree plantation drives promote environmental sustainability and enhance the green cover in rural areas. Together, we can cultivate a healthier planet." },
                            { img: banner5, title: 'Training Programs', description: 'APS Social Welfare Foundation conducts training and skill development programs, equipping individuals with the knowledge and abilities to secure better livelihoods. Together, we can empower communities.' },
                        ].map((slide, index) => (
                            <div key={index} className="w-full flex-none relative">
                                <img className="w-full h-full object-cover" src={slide.img} alt={`Slide ${index + 1}`} />
                                <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                                <div className="absolute inset-0 flex flex-col justify-center items-start text-left text-white p-6 lg:p-20">
                                    <h2 className="text-3xl lg:text-4xl font-bold mb-2">{slide.title}</h2>
                                    <p className="text-base lg:text-lg mb-4">{slide.description}</p>
                                    <button className="text-white px-4 py-2 rounded-md border-2 hover:bg-green-600">Learn More {' >>'}</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Progress Buttons */}
                <div className="absolute bottom-14 left-4 right-4 flex justify-between space-x-2">
                    
                    {buttons.map((button, index) => (
                        <button
                            key={index}
                            onClick={() => {
                                setCurrentIndex(button.index);
                                setProgress(0);
                            }}
                            className={`w-1/6 md:w-1/4 relative overflow-hidden hover:text-green-600 ${currentIndex === button.index ? ' text-green-500' : ' text-gray-200'}`}
                        >
                            <div className="h-1 bg-gray-200">
                                <div
                                    className={`h-1 bg-green-500 absolute top-0 left-0`}
                                    style={{ width: currentIndex === button.index ? `${progress}%` : '0%' }}
                                ></div>
                            </div>
                            {/* hide in mobile */}
                            <p className='pt-4 text-white text-lg md:hidden md:text-xl hover:text-green-500'>{button.textM}</p> 
                          
                            <p className='pt-4 text-white text-lg hidden md:block hover:text-green-500'>{index + 1}. {button.text}</p>
                        </button>
                    ))}
                </div>
            </div>

            {/* Intro Section */}
            <div className="py-16 bg-gray-100 max-w-[90vw] mx-auto">
                <div className="container mx-auto px-0 md:px-4 flex flex-col lg:flex-row items-start justify-between space-y-8 lg:space-y-0 lg:space-x-8">
                    <div className="w-full lg:w-1/2 space-y-8">
                        <div>
                            <h2 className="text-4xl font-bold text-green-900 mb-6">
                                Welcome to <span className='text-green-600'>APS SOCIAL WELFARE AND YOUTH EMPOWERMENT FOUNDATION</span>
                            </h2>
                            <p className="text-gray-700 text-lg leading-relaxed mb-4">
                                APS SOCIAL WELFARE AND YOUTH EMPOWERMENT FOUNDATION (AFSSW&YE FOUNDATION) is a non-profit organization that has received 5 years of love and support.
                            </p>
                            <p className="text-gray-700 text-lg leading-relaxed mb-4">
                                Join our hands to feed more poor people during this crisis. No one left hungry, saving lives, helping feed the forgotten. Social welfare and empowering youth by providing livelihoods and preparing them to face the world of opportunities.
                            </p>
                        </div>
                    </div>

                    {/* Donate Helper Component */}
                    <DonateHelper />
                </div>

                
                {/* mission vision Section */}
                <div className="relative mx-auto my-5 p-0 md:p-5  bg-gray-100">

                    <div className="flex flex-col space-y-12">
                        {/* Card 1 */}
                        <div className="relative flex items-center bg-gray-200">

                            <div className="bg-white p-2 md:p-6 shadow-lg rounded-lg w-full lg:w-3/4 mr-auto border-dashed border-2 border-green-800">
                                <h3 className="text-2xl font-semibold text-green-900">Our Mission</h3>
                                <p className="text-gray-600 mt-2">
                                    To empower rural communities through accessible and equitable education and healthcare, fostering holistic development and sustainable well-being
                                </p>
                            </div>
                            <FontAwesomeIcon icon={faRocket} className="mx-auto text-green-900 text-9xl" />
                        </div>

                        {/* Card 2 */}
                        <div className="relative flex items-center bg-gray-200">
                            <FontAwesomeIcon icon={faEye} className="mx-auto text-green-900 text-9xl" />
                            <div className="bg-white p-2 md:p-6 shadow-lg rounded-lg w-full lg:w-3/4 ml-auto border-dashed border-2 border-green-800">
                                <h3 className="text-2xl font-semibold text-green-900">Our Vision</h3>
                                <p className="text-gray-600 mt-2">
                                    A world where every individual has access to quality education and healthcare resources regardless of their socio-economic background, enabling them to lead fulfilling and healthy lives, and contribute positively to society.
                                </p>
                            </div>

                        </div>

                    </div>
                </div>

                {/* Vice Chairman's Message */}
                <div className="vice-chairman-message py-16 bg-gradient-to-r from-gray-100 via-gray-50 to-gray-100 border-t-2 border-b-2 border-gray-300 max-w-[90vw] mx-auto">
                    <div className="container mx-auto px-0 md:px-2">
                        <div className="bg-white shadow-lg rounded-lg p-8 md:p-12">
                            <h2 className="text-4xl font-extrabold text-green-700 mb-8 text-center">
                                Message from the Vice Chairman
                            </h2>
                            <div className="text-lg text-gray-800 leading-relaxed mb-6 space-y-6">
                                <p>
                                    As the Vice Chairman of <strong >APS Foundation</strong>, I am honored to share our vision and commitment to <strong ><i> "Heal the world and make it a better place for living" </i></strong> and creating lasting change in the communities we serve. Our mission is rooted in the belief that every individual has the potential to thrive, and our work across <strong >education</strong>, <strong >healthcare</strong>, <strong >youth and women’s empowerment</strong>, and <strong >sustainable agriculture</strong> reflects this core value.
                                </p>
                                <p>
                                    <strong >Education</strong> is the cornerstone of progress. By providing access to quality education, we are equipping the next generation with the knowledge and skills they need to succeed. We recognize that when young people are educated, they become empowered advocates for change, driving innovation and progress in their rural communities.
                                </p>
                                <p>
                                    <strong >Healthcare</strong> is a fundamental right, and we are dedicated to ensuring that everyone has access to <strong >essential health services</strong>. By raising awareness and providing resources, we aim to improve health outcomes and enhance the quality of life for rural communities.
                                </p>
                                <p>
                                    Our commitment to <strong >youth empowerment</strong> is vital in shaping future leaders. Through <strong >mentorship, skill development, and leadership training</strong>, we inspire young individuals to take charge of their futures and make a meaningful impact in society, simultaneously making them job-ready and tackling the problem of unemployment in rural areas.
                                </p>
                                <p>
                                    <strong >Women's empowerment</strong> is essential for achieving true equality and sustainable development. We strive to create opportunities that enable women to realize their full potential, whether through <strong >economic independence</strong>, education, or leadership roles, while making them <strong >self-aware</strong> about their own <strong >health and hygiene</strong>.
                                </p>
                                <p>
                                    Finally, <strong >sustainable agriculture</strong> is key to food security and community resilience. By promoting sustainable practices and providing resources to farmers, we are helping to build a healthier, more sustainable future for all.
                                </p>
                                <p>
                                    Together, we can drive <strong >meaningful change</strong>. I invite you to join us in our mission to <strong >empower individuals</strong> and <strong >transform communities</strong>. Your support can make a profound difference in the lives of many. Thank you for being a part of our journey.
                                </p>
                            </div>
                            <div className="border-t-2 border-gray-200 pt-6 text-end">
                                <p className="text-lg font-semibold mb-2">
                                    Warm regards,
                                </p>
                                <p className="text-xl font-bold ">
                                    Mr. Sandip Hadbal
                                </p>
                                <p className="text-lg font-medium text-gray-600">
                                    <i>
                                        Vice Chairman APS Foundation
                                    </i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <OurValues></OurValues>


            </div>
        </div>
    );
};

export default Public;
