import React from 'react';

import team_list1 from '../img/team1.jpg';
import team_list2 from '../img/team_table.png';

const Team = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-center text-5xl font-extrabold mb-6 text-cyan-800">Our Team</h1>
            <div className="flex flex-wrap justify-center">
                
                <div className="w-full sm:w-1/2 px-4 mb-8">
                    <img src={team_list2} alt="Team List 2" className="w-full h-auto rounded-lg shadow-lg" />
                </div>
            </div>
        </div>
    );
}

export default Team;
