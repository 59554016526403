import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css'; // Import Lightbox styles

import article1 from '../img/mediacoverage/img1.png'; 
import article2 from '../img/mediacoverage/img2.png';
import article3 from '../img/mediacoverage/img3.png';
import article4 from '../img/mediacoverage/img4.png';

const MediaCoverage = () => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const articles = [
    { src: article1, caption: 'Media Coverage 1' },
    { src: article2, caption: 'Media Coverage 2' },
    { src: article3, caption: 'Media Coverage 3' },
    { src: article4, caption: 'Media Coverage 4' },
  ];

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  return (
    <section className="py-12 bg-gray-100">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-5xl font-extrabold mb-6 text-cyan-800 text-center">Media Coverage</h2>
        <p className="text-center text-lg text-gray-700 mb-12 max-w-3xl mx-auto">
          Here are some of the articles that covered our work and progress in various newspapers.
        </p>

        <div className="grid gap-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
          {articles.map((article, index) => (
            <div
              key={index}
              className="overflow-hidden rounded-lg shadow-lg transition-transform transform hover:scale-105 cursor-pointer border-4 border-cyan-600 hover:border-cyan-900"
              onClick={() => openLightbox(index)}
            >
              <img
                src={article.src}
                alt={`Media Coverage ${index + 1}`}
                className="w-full h-full object-cover rounded-lg  shadow-md transition-all duration-300"
              />
            </div>
          ))}
        </div>

        {/* Lightbox for image preview */}
        {lightboxOpen && (
          <Lightbox
            open={lightboxOpen}
            close={closeLightbox}
            slides={articles}
            currentIndex={photoIndex}
            slideCaption={(slide) => (
              <div className="text-center">
                <p className="text-lg text-gray-800">{slide.caption}</p>
              </div>
            )}
          />
        )}
      </div>
    </section>
  );
};

export default MediaCoverage;
