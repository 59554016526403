import React from 'react';

// Import your images
import blanketDistribution1 from '../img/bl1.JPEG';

import blanketDistribution2 from '../img/bl2.JPEG';
import medicalCamp1 from '../img/med1.jpg';
import medicalCamp2 from '../img/med2.jpg';

const Gallery = () => {
  // Define categories and their respective images
  const categories = [
    {
      name: 'Blanket Distribution',
      images: [blanketDistribution1, blanketDistribution2],
    },
    {
      name: 'Medical Camp',
      images: [medicalCamp1, medicalCamp2],
    },
    // Add more categories as needed
  ];

  return (
    <div className="py-12 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-5xl font-extrabold mb-6 text-cyan-800 text-center">
          Gallery
        </h1>
        <div className="grid grid-cols-1 gap-8">
          {categories.map((category, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg p-4">
              <h2 className="text-xl font-bold mb-4">{category.name}</h2>
              <div className="flex overflow-x-auto">
                {category.images.map((image, idx) => (
                  <div key={idx} className="mr-4">
                    <img
                      src={image}
                      alt={`${idx + 1}`}
                      className="w-48 h-auto rounded-lg shadow-lg"
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
