import React from 'react';
import palghar from '../img/palghar.png';
import { FaMapMarkerAlt } from 'react-icons/fa'; // FontAwesome Icon for location markers

const GeoPresence = () => {
  return (
    <section className="py-12 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* <h2 className="text-4xl font-bold text-center text-green-800 mb-12">Geographical Presence</h2> */}

        {/* Flex Container for Image and Text */}
        <div className="flex flex-col md:flex-row items-center md:items-start">
          {/* Left Side: Image */}
          <div className="w-full md:w-1/2 p-4">
            <img 
              src={palghar} 
              alt="Map of Palghar, Maharashtra, India" 
              className="rounded-lg shadow-lg border-2 border-dashed border-green-900 w-full"
            />
          </div>

          {/* Right Side: Text */}
          <div className="w-full md:w-1/2 p-4">
            <div className="mb-8">
              <h3 className="text-3xl font-semibold text-green-900 mb-6">Palghar District</h3> 
              <h4 className="text-2xl font-medium text-green-900 mt-4 mb-4">Talukas -</h4> 
              <p  className="text-xl font-medium text-green-900 mt-4 mb-4" >Wada, Vikramgad, Jawhar, Mokhada, Dahanu, Palghar, Talasari </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GeoPresence;
